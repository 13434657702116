import api from './axios/config';

export async function createSpot(spot) {
  try {
    const { data } = await api.post('spot', spot);

    return { success: true, data };
  } catch (err) {
    return { success: false, err };
  }
}

export async function updateSpotById(spot) {
  try {
    const { data } = await api.patch(`spot/rowName`, spot);

    return { success: true, data };
  } catch (err) {
    return { success: false, err };
  }
}

export async function deleteSpotById(spot) {
  try {
    const { data } = await api.delete(`spot/${spot.name}/${spot.rowName}`);

    return { success: true, data };
  } catch (err) {
    return { success: false, err };
  }
}