<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
  >
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn 
        v-bind="activatorProps"
        variant="outline"
        icon="mdi-qrcode-scan"
        size="medium"
        class="ml-2"
      />
    </template>

    <v-card
      title="Ler QR Code"
    >
      <div class="d-flex flex-column align-center justify-center ga-2">
        <div class="text-subtitle-1">
          <strong>{{ allowCamera ? 'Aponte o QR Code' : 'Abrir Leitor' }}</strong>
        </div>
        
        <QrStream
          v-if="allowCamera" 
          @decode="onDecode"
        >
          <div style="color: red;" class="frame"></div>
        </QrStream>

        <div
          v-else
          class="d-flex justify-center align-center pa-2"
        >
          <v-btn 
            variant="outline"
            icon="mdi-qrcode-scan"
            size="large"
            @click="allowCamera = true"
          />
        </div>

     </div>
    </v-card>

    <SpotDialog
      :spot-obj="spotObj"
      @close="dialog = false"
      from-q-r-code
    />
  </v-dialog> 
</template>

<script>
import SpotDialog from './Spot/SpotDialog.vue';
import { QrStream } from 'vue3-qr-reader'

import {
  LOAD_TYPES_SERVER,
  LOAD_TYPES_UI_TO_SERVER
} from '@/helpers/constants/app.constants';

export default {
  name: 'QRCodeReaderDialog',

  components: {
    QrStream,
    SpotDialog
  },

  data: () => ({
    dialog: false,
    allowCamera: true,
    spotObj: null,
    parsedData: null
  }),
  
  methods: {
    onDecode(value) {
      console.log(value);

      this.allowCamera = false;

      this.extractData(value);

      let spotObj = {
        loadType: LOAD_TYPES_UI_TO_SERVER[this.parsedData.TIPO.toUpperCase()],
        description: this.parsedData.DESCRICAO,
        batch: this.parsedData.PV,
        customerOrProvider: this.parsedData.CLIENTE,
        volume: parseInt(this.parsedData.VOLUMES),
        quantity: parseInt(this.parsedData.QUANTIDADE)
      }

      if (spotObj.loadType === LOAD_TYPES_SERVER.FINISHED) {
        spotObj.productCode = this.parsedData.COD;
        const dataString = this.parsedData.ENTREGA;
        const [dia, mes, ano] = dataString.split('/');

        spotObj.deliveryDate = new Date(ano, mes - 1, dia);
      }

      this.spotObj = spotObj;
    },

    extractData(content) {
      const regex = /(\w+):\s*([^\n]+)/g;
      let match;
      const result = {};

      while ((match = regex.exec(content)) !== null) {
        const key = match[1].trim().toUpperCase();
        const value = match[2].trim().replace('\r\n', ' ').toUpperCase();

        result[key] = value;
      }

      this.parsedData = result;
    }
  }
}
</script>