<template>
  <div class="dashboard-table">
    <v-card 
      flat
      class="rounded-lg ma-1"
    >
      <v-card-title class="d-flex align-center pe-2">
        <div class="d-flex align-center ga-2">
          Geral
          <QRCodeReaderDialog v-if="isAdminUser || isExpUser"/>
        </div>

        <v-spacer></v-spacer>

        <div class="d-flex align-center w-50 px-2 ga-2">
          <v-text-field
            v-model="search"
            density="compact"
            label="Procurar"
            prepend-inner-icon="mdi-magnify"
            variant="solo-filled"
            flat
            hide-details
            single-line
          ></v-text-field>
          <v-select 
            v-model="loadType"
            hide-details
            density="compact"
            variant="solo-filled"
            placeholder="Filtrar Carga"
            :items="selectFilterOptions"
          />
        </div>
      </v-card-title>

      <v-data-table 
        v-model:search="search" 
        :items="items"
        :headers="headers"
      >
        <template v-slot:[`item.updatedAt`]="{ item }">
          <div>
            <v-chip
              v-if="item.updatedAt"
              variant="outlined"
            >
            {{ getBrazilianFormatDate(item.updatedAt) }}
            </v-chip>
          </div>
        </template>

        <template v-slot:[`item.deliveryDate`]="{ item }">
          <div>
            <v-chip
            v-if="item.deliveryDate"
            :color="getChipColor(item.deliveryDate)"
            size="default"
            variant="outlined"
          >
          {{ getBrazilianFormatDate(item.deliveryDate) }}
          </v-chip>
          </div>

        </template>

        <template v-slot:[`item.loadType`]="{ item }">
          <div>
            <v-chip 
            :color="getLoadTypeChipColor(item.loadType)"
          >
            {{ LOAD_TYPES_ABBREVIATION[item.loadType] }}
          </v-chip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import QRCodeReaderDialog from '../QRCodeReaderDialog.vue';

import {
  DATE_FORMAT, 
  DASHBOARD_TABLE_HEADERS,
  LOAD_TYPES,
  LOAD_TYPES_ABBREVIATION
} from '@/helpers/constants/app.constants';

import { sortList } from '@/helpers/services/app.services';

import { mapState, mapGetters } from 'vuex';
 
import dayjs from 'dayjs';

export default {
  name: 'DashboardTable',

  components: {
    QRCodeReaderDialog
  },

  data: () => ({
    search: '',
    loadType: null
  }),

  computed: {
    ...mapState('spot', ['spots']),
    ...mapGetters('user', ['isAdminUser', 'isExpUser']),

    selectFilterOptions() {
      const list = Object.values(LOAD_TYPES);

      list.unshift('TODOS');

      return list;
    },

    LOAD_TYPES() {
      return LOAD_TYPES;
    },

    LOAD_TYPES_ABBREVIATION() {
      return LOAD_TYPES_ABBREVIATION;
    },

    items() {
      return sortList(this.spots.map((spot) => {
        return {
          ...spot,
          name: `${spot.rowName}-${spot.name}`
        }
      })).filter((spot) => {
        if (this.loadType === 'TODOS') {
          return true;
        }

        return LOAD_TYPES[spot.loadType] === this.loadType
      })
    },

    headers() {
      return DASHBOARD_TABLE_HEADERS;
    }
  },

  mounted() {
    this.loadType = LOAD_TYPES.FINISHED;
  },

  methods: {
    getBrazilianFormatDate(date) {
      return dayjs(date).format(DATE_FORMAT.BRAZILIAN);
    },

    getLoadTypeChipColor(value) {
      if (LOAD_TYPES[value] === LOAD_TYPES.EMPTY) {
        return 'indigo';
      } else if (LOAD_TYPES[value] === LOAD_TYPES.FINISHED) {
        return 'cyan'
      } else if (LOAD_TYPES[value] === LOAD_TYPES.SEMI_FINISHED) {
        return 'teal'
      } else {
        return 'orange'
      }
    },

    getChipColor(date) {
      if (this.getBrazilianFormatDate(dayjs()) === this.getBrazilianFormatDate(date)) {
        return 'orange';
      }

      const isGreaterThan2Days = this.isDateGreaterThan(date, 2);
      const isGreaterThan3Days = this.isDateGreaterThan(date, 3);

      if (isGreaterThan3Days) {
          return 'red'
      } else if (isGreaterThan2Days) {
          return 'yellow'
      } else {
          return 'green'
      }
    },

    isDateGreaterThan(date, days) {
      const now = dayjs();
      const targetDate = dayjs(now);
      const difference = targetDate.diff(date, 'day');
    
      return difference > days;
    }
  }
}
</script>
